<template>
    <LiefengContent>
        <template v-slot:title>机构首次发布内容情况统计</template>
        <template v-slot:toolsbarRight>
            <Form class="search" :label-width="100" :inline="true">
                <!-- <FormItem label="组织维度">
                    <Select transfer style="text-align: left; width: 150px" v-model="dimensionId" placeholder="请选择组织维度">
                        <Option :value="item.id" v-for="(item, index) in codeOptionsTwo" :key="index">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="时间段：">
                    <DatePicker
                        separator=" 至 "
                        :value="date"
                        format="yyyy-MM-dd"
                        type="daterange"
                        placement="bottom-end"
                        placeholder="选择时间段"
                        style="width: 220px"
                        @on-change="changeDate"
                        
                    ></DatePicker>
                </FormItem>
                <Button
                    type="info"
                    icon="ios-search"
                    @click="
                        () => {
                            hadlePageSize()
                        }
                    "
                >
                    查询
                </Button> -->
                <Button
                    type="default"
                    icon="md-download"
                    @click="
                        () => {
                            downloadData()
                        }
                    "
                >
                    导出报表
                </Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                ref="tab"
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :fixTable="true"
                :hidePage="true"
                :showsummary="true"
                :summaryData="summaryData"
                :showIndex="false"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/userscount')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            seleteType: "按区",
            date: (() => {
                const end = new Date()
                const start = new Date()
                end.setTime(start.getTime() + 3600 * 24 * 7 * 1000)
                return [this.$core.formatDate(new Date(start), "yyyy-MM-dd hh:mm:ss"), this.$core.formatDate(new Date(end), "yyyy-MM-dd hh:mm:ss")]
            })(),
            talbeColumns: [
                {
                    title: "序号",
                    key: "index",
                    width: 100,
                    align: "center",
                },
                {
                    title: "组织维度",
                    key: "dimensionName",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: async () => {
                                        this.$core.openLayerFrame({
                                            type: 2,
                                            title: params.row.orgName,
                                            content: `/page#/firststatisticsdetail?dimensionId=${params.row.dimensionId}&name=${params.row.dimensionName}`,
                                            area: ["100%", "100%"],
                                        })
                                    },
                                },
                            },
                            params.row.dimensionName
                        )
                    },
                },
                {
                    title: "账号开通机构数",
                    key: "statInNum",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "已开发发布内容机构数",
                    key: "orgPubInfoNum",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                },
                                on: {
                                    click: async () => {
                                        console.log('row',params.row);
                                        if(params.row.index == '合计') return
                                        this.$core.openLayerFrame({
                                            type: 2,
                                            title: params.row.orgName,
                                            content: `/page#/firststatisticsdetail?dimensionId=${params.row.dimensionId}&name=${params.row.dimensionName}`,
                                            area: ["100%", "100%"],
                                        })
                                    },
                                },
                            },
                            params.row.orgPubInfoNum
                        )
                    },
                },
                {
                    title: "激活占比",
                    key: "scale",
                    minWidth: 100,
                    align: "center",
                },
            ],
            tableData: [],
            loading: false,
            pageSize: 100,
            total: 0,
            curPage: 1,
            summaryData: {},
            onlineType: "",
            codeOptionsTwo: [],
        }
    },
    methods: {
        changeDate(value) {
            this.date = [this.$core.formatDate(new Date(value[0]), "yyyy-MM-dd hh:mm:ss"), this.$core.formatDate(new Date(value[1]), "yyyy-MM-dd hh:mm:ss")]
            console.log(this.date)
        },
        downloadData() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？</p>",
                onOk: () => {
                    this.$refs.tab.$refs.lftable.exportCsv({
                        filename: "机构首次发布内容情况统计",
                    })
                    // let tHeader = ["组织维度", "账号开通机构数", "已开发发布内容机构数", "激活占比"]
                    // let filterVal = ["dimensionName", "statInNum", "orgPubInfoNum", "scale"]
                    // let tableData = JSON.parse(JSON.stringify(this.tableData))
                    // this.$core.exportExcel(tHeader, filterVal, tableData, "")
                },
            })
        },
        hadlePageSize() {
            this.loading = true
            this.$get("/statistic/api/symanage/pc/manages/selectByOrgCodeV2", {
                oemCode: parent.vue.oemInfo.oemCode,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList.map((item,index) =>{
                            return{
                                ...item,
                                index:index+1
                            }
                        })
                        let statInNum = 0,
                            orgPubInfoNum = 0
                        this.summaryData = {}
                        var sums = {}
                        this.tableData.map(item => {
                            statInNum = statInNum + item.statInNum
                            orgPubInfoNum = orgPubInfoNum + item.orgPubInfoNum
                        })
                        sums = {
                            index:'合计',
                            dimensionName:'',
                            statInNum,
                            orgPubInfoNum,
                            scale:''
                        }
                        this.tableData.push(sums)
                        // sums["index"] ={
                        //     key:"index",
                        //     value:'合计'
                        // }
                        // sums[""] = {
                        //     key: "dimensionName",
                        //     value: "",
                        // }
                        // sums["statInNum"] = {
                        //     key: "statInNum",
                        //     value: ,
                        // }
                        // sums["orgPubInfoNum"] = {
                        //     key: "orgPubInfoNum",
                        //     value: ,
                        // }
                        // sums["scale"] = {
                        //     key: "",
                        //     value: "",
                        // }

                        // this.summaryData = sums
                    } else {
                        this.tableData = []
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败",
                    })
                })
        },
        getCode() {
            this.$get(
                "/orgzz/pc/dimension/selectAuthorizationDimension",
                {
                    appCode: "",
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                this.codeOptionsTwo = [{ id: "", code: "", name: "全部" }]
                if (res.code == 200) {
                    res.dataList.map(item => {
                        if (item) {
                            this.codeOptionsTwo.push(item)
                        }
                    })
                }
                // this.codeOptions = res.dataList
            })
        },
    },
    mounted() {
        this.getCode()
        this.hadlePageSize()
    },
    components: {
        LiefengContent,
        LiefengTable,
    },
}
</script>
    
<style scoped lang='less'>
/deep/ .ivu-radio-group {
    display: flex;
    margin-right: 20px;
}
</style>