import { render, staticRenderFns } from "./firststatistics.vue?vue&type=template&id=77baca3a&scoped=true&"
import script from "./firststatistics.vue?vue&type=script&lang=js&"
export * from "./firststatistics.vue?vue&type=script&lang=js&"
import style0 from "./firststatistics.vue?vue&type=style&index=0&id=77baca3a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77baca3a",
  null
  
)

export default component.exports